@import '../../../scss/colors.scss';

.goa-footer {
  padding: 2rem 0;

  .goa-footer-link {
    margin: 0 auto;
    font-size: 0.8em;
    font-weight: 700;
  }
}
