@import '../../../scss/colors.scss';

.video-thumbnail {
  cursor: pointer;
  padding: 1.5rem 1rem;
  align-items: center;

  &:hover,
  &.selected {
    background-color: $goa-black-4;
    transition: all 0.3 ease;

    .title {
      color: $goa-black-1;
    }
  }

  .img-video {
    border-radius: 0.5rem;

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: black, $alpha: 0.15);
    }
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
  }

  .text {
    margin: 0;
    color: $goa-black-2;
    font-size: 0.8rem;
  }
}
