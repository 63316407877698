$goa-yellow: #ffcb77;
$goa-yellow-1: #ffcb77;
$goa-yellow-2: #ffdba1;
$goa-yellow-3: #ffe4bb;
$goa-yellow-4: #fff0d8;

$goa-orange: #f79e6b;
$goa-orange-1: #f79e6b;
$goa-orange-2: #fabc98;
$goa-orange-3: #faceb5;
$goa-orange-4: #fce2d3;

$goa-red: #e57e67;
$goa-red-1: #e57e67;
$goa-red-2: #eca495;
$goa-red-3: #f1beb3;
$goa-red-4: #f9d9d3;

$goa-black: #1a1a1a;
$goa-black-1: #1a1a1a;
$goa-black-2: #5f5f5f;
$goa-black-3: #8c8c8c;
$goa-black-4: #bbbbbb;

$goa-blue: #003488;
$goa-blue-1: #003488;
$goa-blue-2: #4f72ad;
$goa-blue-3: #819ac4;
$goa-blue-4: #b4c4dd;

$goa-cerulean: #086194;
$goa-cerulean-1: #086194;
$goa-cerulean-2: #5890b6;
$goa-cerulean-3: #87b0c9;
$goa-cerulean-4: #b7d0e0;

$goa-teal: #1a8198;
$goa-teal-1: #1a8198;
$goa-teal-2: #62a7b7;
$goa-teal-3: #8ebfcb;
$goa-teal-4: #bcdae2;