@import '../../../scss/colors.scss';
@import '../../../scss/variables.scss';

.navbar {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15);

  &.bg-light {
    background-color: white !important;
  }

  &.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.5rem 1rem;

    &:hover {
      color: $goa-red;
    }
  }

  .navbar-brand {
    padding: 0;
  }

  .dropdown-item {
    &.dropdown-item.active {
      background-color: white;
    }

    &:hover {
      color: $goa-red;
    }
  }

  .dropdown-menu.show {
    border: none;
    border-radius: 8px;
    box-shadow: 0 0.25rem 0.5rem rgba(black, 0.15);
  }

  .dropdown-toggle {
    &.nav-link {
      margin-right: 0.5rem;
      border-right: 1px solid #e9ecef;
    }

    &::after {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      vertical-align: middle;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: white;
    color: $goa-red;
  }
}

.navbar-btn.nav-link {
  display: none;
}

.navbar-collapse > .navbar-btn.nav-link {
  display: block;
}

@media (max-width: $breakpoint-laptop) {
  .navbar-nav {
    .dropdown-menu.show {
      border: none;
      box-shadow: none;
    }

    .dropdown-toggle {
      &.nav-link {
        border-right: none;
      }
    }
  }

  .navbar-btn.nav-link {
    display: block;
    margin: auto 0 auto auto;
  }

  .navbar-collapse {
    margin: 0.5rem 0;

    .navbar-btn.nav-link {
      display: none;
    }
  }

  .navbar-light .navbar-toggler {
    border: none;
    outline: none;
  }
}
