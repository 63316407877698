@import '../../../scss/colors.scss';

.image-card {
  margin: 0 0 1.5rem;

  &.circle {
    .card-img {
      width: 60%;
      margin: 1.25rem 20% 1rem;
      border-radius: 50%;
    }
  }

  .card {
    min-height: 132px;
    height: 100%;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0.1rem 0.25rem 1rem rgba(black, 0.15);
    &:hover {
      box-shadow: 0 0.1rem 0.5rem rgba(black, 0.15);
      .card-text {
        color: $goa-black-3;
      }
    }
  }

  .card-title {
    font-weight: 600;
    font-size: 1.25rem;
  }

  .card-text {
    margin: 0 0 1rem;
    color: $goa-black-2;
    font-size: 0.8rem;
  }
}
