@import '../../../scss/colors.scss';

.image-list-item {
  padding: 0 2rem 1rem 0;

  .img {
    margin: 0 1rem 0 0;
  }
  .title {
    font-size: 1rem;
    font-weight: 700;
  }
  .text {
    font-size: 0.8rem;
  }
}
