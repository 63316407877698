// Override default variables before the import
// $body-bg: #000;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Import Game of Apps custom variables
@import './scss/colors.scss';

// Loader
.loader {
  width: 100%;
  .spinner {
    margin: 2rem auto;
  }
}

// Generic GOA components
body {
  margin: 0;
  color: $goa-black-1;
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif, -apple-system, BlinkMacSystemFont;
  font-weight: 400;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.goa-block {
  padding: 4rem 2rem;
}

.bg-grey {
  background-color: #f8f9fa;
}

.divider {
  display: block;
  margin: 0 2rem;
  border: 1px solid #e9ecef;
}

.card-details {
  height: 140px;
  overflow: hidden;
  // Need a check on data
}

// Link Overrides
a {
  color: $goa-red;
  &:hover {
    color: $goa-orange;
    text-decoration: none;
  }
}
.nav-link {
  font-weight: 600;
}
.btn {
  font-size: 14px;
  font-weight: 700;
  padding: 0.375rem 2rem;
  text-transform: capitalize;
  border-radius: 6px;
  box-shadow: 0 0.2rem 0.5rem rgba(229, 126, 103, 0.3);
}
.btn-primary {
  color: $white;
  background-color: $goa-orange;
  border-color: $goa-orange;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    color: $white;
    background-color: $goa-red;
    border-color: $goa-red;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 158, 107, 0.3);
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 126, 103, 0.3);
  }
  &:disabled {
    cursor: default;
    background-color: $goa-black-4;
    border-color: $goa-black-4;
    box-shadow: none;
  }
}

// Text Overrides
h1 {
  font-size: 3rem;
  font-weight: 700;
}

h2 {
  margin: 0 0 2rem 0;
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
}

h4 {
  margin: 0 0 1.5rem 0;
  font-size: 1rem;
  font-weight: 600;
}

.invert {
  color: white;
}

.center {
  text-align: center;
}

.text-description {
  white-space: pre-wrap;
}
