@import '../../../scss/colors.scss';

.goa-pagination {
  padding: 2rem;

  .goa-pagination-link {
    font-size: 1rem;
    font-weight: 700;
  }
}
